.waitAbout {
    @extend %reset;
    @extend %section;
    width: 100%;
    @include bg('../media/main-zagl-back-2.png', 100% auto, center top);
    padding: 0 0 10rem 0;

    @include media(0, $mediaM) {
        @include bg('../media/main-zagl-back-2-mob.png', 100% auto, center 20rem);
        padding-bottom: 15rem;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        align-items: center;
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 103rem;
        background: #fff;
        border-radius: 3.5rem;
        border: .4rem solid $colorDark;
        padding: 4.7rem 4rem 4rem 4rem;
        align-items: center;
        margin: -6rem 0 7rem 0;
        z-index: 2;
        position: relative;

        @include media(0, $mediaM) {
            width: 100%;
            padding: 2.5rem 2.2rem;
            margin-top: -5.5rem;
            border-radius: 3.8rem;
        }

        & p {
            @extend %reset;
            max-width: 100%;
            text-align: center;
            font-size: 2.8rem;
            line-height: 1.3;
            color: $colorDark;
            font-weight: 500;

            @include media(0, $mediaM) {
                font-size: 2.25rem;
                line-height: 1.35;
            }
        }

        & a {
            @extend %reset;
            @extend %click;
            font-weight: 500;
            line-height: 0.8;
            display: inline-block;
            border-bottom: 1px solid $colorDark;
            color: $colorDark;
        }

        & p+p {
            margin-top: 1rem;

            @include media(0, $mediaM) {
                margin-top: 0;
            }
        }
    }

    &__button {
        @extend %reset;
    }
}