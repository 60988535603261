.page {
    @extend %reset;
    width: 100%;
    // transform: translate(0, -600px);

    &._inner {
        @extend %innerPage;
    }

    &__section {
        @extend %reset;
        width: 100%;
        position: relative;
        z-index: 1;

        &._prizeAndWinners {
            // @include bg('../media/back-4.png', 100% auto, center top);

            @include media(0, $mediaM) {
                background: none;
            }
        }
    }
}