﻿@font-face {
    font-weight: 400;
    font-family: 'FiraSans';
    font-style: normal;
    src: local('DolceCaffe'), url('../fonts/FiraSans/FiraSans.woff');
}

@font-face {
    font-weight: 500;
    font-family: 'FiraSans';
    font-style: normal;
    src: local('DolceCaffe'), url('../fonts/FiraSans/FiraSansMedium.woff');
}

@font-face {
    font-weight: 600;
    font-family: 'FiraSans';
    font-style: normal;
    src: local('DolceCaffe'), url('../fonts/FiraSans/FiraSans-Bold.woff');
}