.indexHeader {
    @extend %reset;
    @extend %section;
    width: 100%;
    height: 107.5rem;
    padding: 15rem 0 11rem 0;
    position: relative;
    z-index: 1;

    @include media(0, $mediaM) {
        height: auto;
        padding-bottom: 14.5rem;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 10rem);
        height: 100%;
        @include bg('../media/main-zagl-back-1.jpg', 100% auto, left 11rem);
        z-index: -1;
        pointer-events: none;

        @include media(0, $mediaM) {
            width: 100%;
            // @include bg('../media/image-main-mob.png', 100% auto, left 8.5rem);
        }
    }

    &::after {
        content: "";
        position: absolute;
        left: 6rem;
        bottom: 9.5rem;
        width: 52rem;
        height: 82rem;
        // @include bg('../media/pack-main-trnsp.png', contain, left bottom);
        z-index: -2;

        @include media(0, $mediaM) {
            display: none;
        }
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        height: 100%;
    }

    &__steps {
        @extend %reset;
        margin: auto 0 0 auto;

        @include media(0, $mediaM) {
            padding-left: 8rem;
            margin: 49rem 0 0 0;
        }

        &Inner {
            @extend %reset;
            @extend %row;
            align-items: center;
            margin: 0 -2rem 0 0;

            @include media(0, $mediaM) {
                margin: 0;
                flex-direction: column;
            }
        }
    }

    &__step {
        @extend %reset;
        width: 31rem;

        @include media(0, $mediaM) {
            width: 32rem;
            height: 23rem;
        }

        &+& {
            margin-left: 2rem;

            @include media(0, $mediaM) {
                margin-left: 0;
                margin-top: 1.5rem;
            }
        }

        &Image {
            @extend %reset;
            width: 100%;
            display: block;
            object-fit: contain;
        }
    }
}