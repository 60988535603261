.waitHeader {
    @extend %reset;
    @extend %section;
    width: 100%;
    height: 117rem;
    @include bg('../media/main-zagl-back-1.jpg', 100% auto, left top);
    position: relative;
    padding: 3.7rem 0 0 0;

    @include media(0, $mediaM) {
        @include bg('../media/main-zagl-back-1-mob.png', 100% auto, left top);
        height: 120rem;
        padding: 2.5rem 0 0 0;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        height: 100%;
        position: relative;

        @include media(0, $mediaM) {
            padding-top: 6.5rem;
        }

        &::before {
            @extend %reset;
            content: "";
            position: absolute;
            top: .5rem;
            left: -5rem;
            width: 15.5rem;
            height: 10rem;
            z-index: 2;
            @include bg('../media/logo-dobry.svg', 100% auto, left top);

            @include media(0, $mediaM) {
                left: 50%;
                top: 0;
                transform: translate(-50%, 0);
                width: 13rem;
                height: 13rem;
                background-position: center top;
            }
        }
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 38.5rem;
        margin: 0 0 0 auto;
        align-items: center;
        position: relative;

        @include media(0, $mediaM) {
            width: 32.5rem;
            margin: 0 auto;
        }

        &::after {
            content: "";
            position: absolute;
            right: 100%;
            top: 100%;
            margin: -17rem -17rem 0 0;
            width: 32rem;
            height: 57rem;
            @include bg('../media/cola-image.png', contain, left top);
            z-index: 2;
            pointer-events: none;

            @include media(0, $mediaM) {
                display: none;
            }
        }
    }

    &__slogan {
        @extend %reset;
        width: 100%;
        height: 18rem;
        object-fit: contain;
        object-position: center top;
        margin-bottom: 3.5rem;

        @include media(0, $mediaM) {
            width: 88%;
            height: 13rem;
            margin-bottom: 2.5rem;
        }
    }

    &__title {
        @extend %reset;
        max-width: 100%;
        font-weight: 500;
        color: #fff;
        font-size: 3.05rem;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 3rem;

        @include media(0, $mediaM) {
            font-size: 2.25rem;
            line-height: 1.25;
            margin-bottom: 2.2rem;
        }
    }

    &__info {
        @extend %reset;
        @extend %col;
        width: 100%;
        border-radius: 3.2rem;
        border: .4rem solid $colorDark;
        background: #F8D000;
        align-items: center;
        padding: 2rem 6.3rem 2.5rem 6.3rem;

        @include media(0, $mediaM) {
            border-radius: 3.5rem;
            padding: 1.7rem 2rem 2rem 2rem;
        }

        &Title {
            @extend %reset;
            max-width: 100%;
            text-align: center;
            font-size: 2.85rem;
            font-weight: 500;
            color: $colorDark;
            margin-bottom: 2rem;

            @include media(0, $mediaM) {
                font-size: 2.6rem;
                margin-bottom: 1.5rem;
            }
        }

        &Inner {
            @extend %col;
            width: 100%;
            align-items: center;

            @include media(0, $mediaM) {
                justify-content: space-between;
                flex-direction: row;
                height: 11rem;
            }
        }

        &Counter {
            @extend %reset;
            @extend %col;
            width: 100%;
            border-radius: 2.7rem;
            border: .4rem solid $colorDark;
            align-items: center;
            background: #fff;
            padding: .9rem 2rem 1.3rem 2rem;
            margin-bottom: 1.5rem;

            @include media(0, $mediaM) {
                width: 14.5rem;
                height: 100%;
                border-radius: 2.4rem;
                padding: .6rem;
                margin-bottom: 0;
            }

            &Value {
                @extend %reset;
                max-width: 100%;
                text-align: center;
                font-size: 7rem;
                font-weight: 500;
                color: $colorDark;
                margin-bottom: -.7rem;

                @include media(0, $mediaM) {
                    font-size: 6.2rem;
                    margin-bottom: -.5rem;
                }
            }

            &Support {
                @extend %reset;
                max-width: 100%;
                text-align: center;
                font-size: 3rem;
                font-weight: 500;
                color: $colorDark;

                @include media(0, $mediaM) {
                    font-size: 2.7rem;
                }
            }
        }

        &Date {
            @extend %reset;
            @extend %row;
            width: 100%;
            border-radius: 36rem;
            justify-content: center;
            align-items: center;
            border: .4rem solid $colorDark;
            background: #ED40A9;
            color: #fff;
            padding: 1.4rem 1rem;
            font-size: 2.3rem;
            font-weight: 500;

            @include media(0, $mediaM) {
                width: calc(100% - 14.5rem - 1.2rem);
                height: 100%;
                border-radius: 2.4rem;
                font-size: 2.1rem;
                text-align: center;
                line-height: 1.2;
            }
        }
    }
}